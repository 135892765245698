import React from 'react';
import { css } from 'linaria';
const preventBodyScrollStyle = "p1dx0952";
/**
 * Wraps target's children and supplies lifecycle hooks
 * to close on esc
 */

class TargetWrapper extends React.Component {
  constructor() {
    super(...arguments);

    this.cleanup = () => {
      document.removeEventListener('keydown', this.handleKeydown);
      document.body.classList.remove(preventBodyScrollStyle);
    };

    this.handleKeydown = e => e.key === 'Escape' && this.props.hideTarget();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);

    if (this.props.preventOverflow) {
      document.body.classList.add(preventBodyScrollStyle);
    }
  }

  componentDidUpdate(prevProps) {
    // If we're opening a drawer, add event handlers and prevent body scroll
    if (this.props.modalType === 'drawer' && this.props.isOpen) {
      document.addEventListener('keydown', this.handleKeydown);
      document.body.classList.add(preventBodyScrollStyle);
    } // If we have just closed a drawer, clean it up


    if (prevProps.modalType === 'drawer' && !this.props.isOpen) {
      this.cleanup();
    }
  }

  componentWillUnmount() {
    this.cleanup();
  }

  render() {
    const {
      hideTarget,
      isOpen
    } = this.props;
    return this.props.children({
      hideTarget,
      isOpen
    });
  }

}

export default TargetWrapper;

require("../../../../.linaria-cache/node_modules/@jetshop/ui/Modal/TargetWrapper.linaria.css");